import { Drawer, styled } from '@mui/material';

import { Routes } from './Routes';

interface NavigationProps {
	open: boolean | undefined;
	handleClose: () => void;
}

export const Navigation = ({ open, handleClose }: NavigationProps) => {
	return (
		<Drawer variant="persistent" PaperProps={{
			sx: {
				backgroundColor: "#f0e4d4"
			}
		}}
			open={open} onClose={handleClose}>
			<DrawerHeader />
			<Routes />
		</Drawer>
	);
};

const DrawerHeader = styled('div')(({ theme }) => ({
	...theme.mixins.toolbar
}));