import { AppBar, Box } from '@mui/material';

import InstagramIcon from '@mui/icons-material/Instagram';
import SearchIcon from '@mui/icons-material/Search';

import { Hamburger } from './Hamburger';
import { AppTitle } from './AppTitle';
import { HEADER_LOGO } from "../../utils/constants";
import { styled } from '@mui/material/styles';

const HeaderLogoContainer = styled('div')(({ theme }) => ({
	flex: 3,
	margin: 5,
	display: "flex",
	justifyContent: "center",
}));

const IconsContainer = styled('div')(({ theme }) => ({
	flex: 3.5,
	margin: 5,
	display: "flex",
	justifyContent: "end",
	// alignItems: "end",
	color: "#765135",
	spacing: 10
}));

interface HeaderProps {
	toggleNavigation: () => void;
}

export const Header = ({ toggleNavigation }: HeaderProps) => {
	const iconStyles = {
		width: 1024 / 15,
		height: 467 / 15,
	};

	return (
		<>
			<AppBar
				position='fixed'
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#f0e4d4",
				}}
			>
				<Box sx={{ flex: 4, display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "row", }} >
					<Hamburger toggleNavigation={toggleNavigation} />
					<AppTitle />
				</Box>
				<HeaderLogoContainer>
					<img style={iconStyles} src={HEADER_LOGO} alt="header-logo" />
				</HeaderLogoContainer>
				<IconsContainer>
					<SearchIcon />
					<InstagramIcon sx={{ cursor: "pointer" }} onClick={() => window.open("https://www.instagram.com/ogi_nutrition/")} />
				</IconsContainer>
			</AppBar >
		</>
	);
};
