import React, { useState } from 'react';

import { Helmet } from 'react-helmet';
import { Box, styled } from "@mui/material";
import ImgsViewer from "react-images-viewer";

import {
	APP_TITLE,
	PAGE_TITLE_HOME,
	YOUTUBE_LINK,
	IMAGE_1,
	IMAGE_3,
	IMAGE_4,
	IMAGE_5,
	IMAGE_6,
	IMAGE_7,
	IMAGE_9,
	IMAGE_11
} from '../utils/constants';

const YoutubePlayer = ({ videoId = YOUTUBE_LINK, start = 0 }) => {
	return (
		<iframe
			title='OGI'
			src="https://player.vimeo.com/video/897692698?autoplay=1&color=ff0179&title=0&byline=0&portrait=0&autoplay=1&loop=1&autopause=0"
			allow="autoplay; fullscreen"
			frameBorder="0"
		></iframe>
	);
}

const ImageContainer = styled('div')(({ theme }) => ({
	display: "flex",
	flex: 1,
	flexDirection: "row",
	lineHeight: 0,
	[theme.breakpoints.down('md')]: {
		flexDirection: "column",
		height: "50%"
	},
}));

const IMAGE_MAPPER = [IMAGE_1, IMAGE_3, IMAGE_4, IMAGE_5, IMAGE_6, IMAGE_7, IMAGE_9]

export const Home = () => {
	const [currentImage, setCurrentImage] = useState(-1);

	return (
		<React.Fragment>
			<Helmet>
				<title>
					{PAGE_TITLE_HOME} | {APP_TITLE}
				</title>
			</Helmet>
			<section >
				<YoutubePlayer />
			</section>

			<Box>
				<ImageContainer>
					<Box sx={{ flex: 1 }} onClick={() => setCurrentImage(0)}>
						<img style={{ width: "100%", height: "100%" }} src={IMAGE_1} alt="main-1" />
					</Box>
					<Box sx={{ flex: 1 }} onClick={() => setCurrentImage(1)}>
						<img style={{ width: "100%", height: "100%" }} src={IMAGE_3} alt="main-2" />
					</Box>
				</ImageContainer>
				<ImageContainer>
					<Box sx={{ flex: 1 }} onClick={() => setCurrentImage(2)}>
						<img style={{ width: "100%", height: "100%" }} src={IMAGE_4} alt="main-3" />
					</Box>
					<Box sx={{ flex: 1 }} onClick={() => setCurrentImage(3)}>
						<img style={{ width: "100%", height: "100%" }} src={IMAGE_5} alt="main-4" />
					</Box>
				</ImageContainer>
				<ImageContainer>
					<img style={{ width: "100%", height: "100%" }} src={IMAGE_11} alt="main-5" />
				</ImageContainer>
				<ImageContainer>
					<Box sx={{ flex: 1 }} onClick={() => setCurrentImage(4)}>
						<img style={{ width: "100%", height: "100%" }} src={IMAGE_6} alt="main-3" />
					</Box>
					<Box sx={{ flex: 1 }} onClick={() => setCurrentImage(5)}>
						<img style={{ width: "100%", height: "100%" }} src={IMAGE_7} alt="main-4" />
					</Box>
				</ImageContainer>
				<ImageContainer onClick={() => setCurrentImage(6)}>
					<img style={{ width: "100%", height: "100%" }} src={IMAGE_9} alt="main-4" />
				</ImageContainer>
			</Box>

			<ImgsViewer
				imgs={IMAGE_MAPPER.map(i => ({ src: i }))}
				currImg={currentImage}
				isOpen={currentImage > -1}
				onClickPrev={() => setCurrentImage(currentImage - 1)}
				onClickNext={() => setCurrentImage(currentImage + 1)}
				onClose={() => setCurrentImage(-1)}
			/>
		</React.Fragment >
	);
};
