import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LocationOnIcon from '@mui/icons-material/LocationOn';


import { FOOTER_LOGAN, QR_CODE, FOOTER_LOGO_BACKGROUND } from '../../utils/constants';

const FooterContainer = styled('div')(({ theme }) => ({
	display: "flex",
	justifyContent: "space-evenly",
	alignItems: "space-evenly",
	flexDirection: "row",
	[theme.breakpoints.down('md')]: {
		flexDirection: "column"
	},
	backgroundColor: "#f0e4d4",
	color: "#765135",
	fontFamily: "Light"
}));

const FooterImageContainer = styled('div')(({ theme }) => ({
	flex: 1,
	display: "flex",
	margin: 10,
	justifyContent: "center",
	textAlign: "center",
	alignItems: "center",
	flexDirection: "column"
}));

const FooterTextContentContainer = styled('div')(({ theme }) => ({
	flex: 1,
	display: "flex",
	margin: 10,
	flexDirection: "row",
	[theme.breakpoints.down('md')]: {
		minHeight: "200px"
	},
}));


const FooterTextTitle = styled('span')(({ theme }) => ({
	flex: 1,
	fontWeight: "bold",
	textAlign: "left",
	textTransform: "uppercase",
	textDecoration: "underline",
	textUnderlinePosition: "under",

}));

const FooterTextContent = styled('span')(({ theme }) => ({
	flex: 8,
	textOverflow: "ellipsis",
	overflow: "hidden",
	textAlign: "left"
}));

const address = " 24/11/11 Võ Oanh, Phường 25, quận Bình Thạnh, TP Hồ Chí Minh, Việt Nam"

const Footer = () => {
	return (
		<FooterContainer>
			{/* <img style={{ width: "100%", height: "100%", objectFit: "fill", position: "absolute", zIndex: 1 }} src={FOOTER_LOGO_BACKGROUND} alt="footer-background" /> */}
			<FooterImageContainer sx={{ zIndex: 999 }}>
				<img style={{ width: "200px", height: "200px", objectFit: "contain" }} src={FOOTER_LOGAN} alt="main-4" />
				<Box>
					<FacebookIcon sx={{ cursor: "pointer" }} fontSize={"large"} onClick={() => window.open("https://www.facebook.com/profile.php?id=100091569859369")} />
					<InstagramIcon sx={{ cursor: "pointer" }} fontSize={"large"} onClick={() => window.open("https://www.instagram.com/ogi_nutrition/")} />
				</Box>

			</FooterImageContainer>
			<FooterTextContentContainer sx={{ zIndex: 999 }}>
				<Box sx={{ flex: 6, marginTop: 5 }}>
					<FooterTextTitle>Liên Hệ</FooterTextTitle>
					<p style={{ color: "#666666", fontFamily: "Bold" }}>OGI NUTRITION</p>
					<FooterTextContent>
						<span style={{ fontSize: 12 }}>
							<LocationOnIcon fontSize="small" />
							{address}
						</span>
					</FooterTextContent>
				</Box>

				<Box sx={{ flex: 4, textAlign: "center", marginTop: 5 }}>
					<img style={{ width: "150px", height: "150px", objectFit: "contain" }} src={QR_CODE} alt="main-4" />
					<p style={{ color: "#666666" }}>SCAN TO SEE MORE</p>
				</Box>
			</FooterTextContentContainer>
		</FooterContainer>
	);
};

export { Footer };
