import { Home } from '../pages/Home';

import { Route } from '../types/Route';

const routes: Array<Route> = [
	{
		key: 'router-home',
		title: 'HOME PAGE',
		description: 'HOME PAGE',
		component: Home,
		path: '/',
		isEnabled: true,
		appendDivider: true,
	},
	{
		key: 'router-story',
		title: 'STORIES',
		description: 'Stories',
		// component: Home,
		path: '/',
		isEnabled: false,
		appendDivider: true,
		outerLink: true
	},
	{
		key: 'router-about-us',
		title: 'ABOUT US',
		description: 'About Us',
		// component: Home,
		path: '/',
		isEnabled: false,
		appendDivider: true,
		outerLink: true
	}
];

export default routes;
