// IMAGES
export { default as IMAGE_1 } from "./images/image-1.jpg";
export { default as IMAGE_2 } from "./images/image-2.jpg";
export { default as IMAGE_3 } from "./images/image-3.jpg";
export { default as IMAGE_4 } from "./images/image-4.jpg";
export { default as IMAGE_5 } from "./images/image-5.jpg";
export { default as IMAGE_6 } from "./images/image-6.jpg";
export { default as IMAGE_7 } from "./images/image-7.jpg";
export { default as IMAGE_8 } from "./images/image-8.jpg";
export { default as IMAGE_9 } from "./images/image-9.jpg";
export { default as IMAGE_10 } from "./images/image-10.jpg";
export { default as IMAGE_11 } from "./images/image-11.jpg";
export { default as IMAGE_12 } from "./images/image-12.jpg";
export { default as QR_CODE } from "./images/qr-code.jpg";
export { default as HEADER_LOGO } from "./images/header-logo.jpg";
export { default as FOOTER_LOGAN } from "./images/footer-slogan.jpg";
export { default as FOOTER_LOGO_BACKGROUND } from "./images/logo-background.jpg";


// APP TEXT
export const APP_TITLE = 'OGI';
export const HAMBERGER_MENU_TEXT = 'SHOP NOW';
export const APP_DESCRIPTION = 'OGI LANDING PAGE';
export const FOOTER_TEXT = `${new Date().getFullYear()} Proudly made in New Zealand by Dan Castro`;

// PAGES TITLE
export const PAGE_TITLE_HOME = 'Home';
export const PAGE_TITLE_DASHBOARD = 'Dashboard';
export const PAGE_TITLE_GH_PRIVATE = 'GitHub Private';
export const PAGE_TITLE_GH_PUBLIC = 'GitHub Public';
export const PAGE_TITLE_CODE = 'Code Editor';
export const PAGE_TITLE_SETTINGS = 'Settings';
export const PAGE_TITLE_PREFERENCES = 'Preferences';

// UI CONSTANTS
export const FOOTER_HEIGHT = 30;
export const HEADER_HEIGHT = 60;
export const DRAWER_WIDTH = 150;

// APP THEME
export const DARK_MODE_THEME = 'dark';
export const LIGHT_MODE_THEME = 'light';

// THIRD PARTIES
export const YOUTUBE_LINK = "rTJCKWakefg";
export const SHOP_URI = "https://www.facebook.com/profile.php?id=100091569859369";
