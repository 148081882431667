/** @jsxImportSource @emotion/react */
import { styled } from '@mui/material';
import { SHOP_URI, HAMBERGER_MENU_TEXT, } from '../../../utils/constants';

const HAMBERGERMENUTEXT = styled('div')(({ theme }) => ({

	cursor: "pointer",
	background: "linear-gradient(to right, #89A687 0%, #D2A463 100%)",
	"-webkit-background-clip": "text",
	"-webkit-text-fill-color": "transparent",
	fontFamily: "Bold",
	fontSize: "20px",
	whiteSpace: "nowrap",
	[theme.breakpoints.down("md")]: {
		fontSize: "15px",
	},
	[theme.breakpoints.down("sm")]: {
		fontSize: "15px"
	}
}));

export const AppTitle = () => (
	<a href={SHOP_URI} style={{ textDecoration: "none" }}>
		<HAMBERGERMENUTEXT>{HAMBERGER_MENU_TEXT}</HAMBERGERMENUTEXT>

	</a>
);
